<template>
  <div class="modal modal-center" id="export">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Export in CSV</h3>
      </div>
      <div class="modal-body">
        <div class="modal-body-container">
          <span>Files selected to export: {{ selectedIds.length }}</span>
        </div>
        <div class="col-12">
          <div class="check-block mt-2"
               :class="{disabled: selectedIds.length === 0, active: isActiveClass(fr.selected)}">
            <input type="checkbox" id="fieldSelected" :class="{disabled: selectedIds.length === 0}"
                   @click="toggleFile(fr.selected)"
                   class="form-check-input form-check-input_bg-blue">
            <label for="fieldSelected">Selected</label>
          </div>
          <div class="check-block mt-2" :class="{active: isActiveClass(fr.pending)}">
            <input type="checkbox" id="fieldPending"
                   class="form-check-input form-check-input_bg-blue"
                   @click="toggleFile(fr.pending)">
            <label for="fieldPending">All Pending Requests</label>
          </div>
          <div class="check-block mt-2" :class="{active: isActiveClass(fr.processed)}">
            <input type="checkbox" id="fieldProcessed"
                   class="form-check-input form-check-input_bg-blue"
                   @click="toggleFile(fr.processed)">
            <label for="fieldProcessed">All Processed Requests</label>
          </div>
          <div class="check-block mt-2" :class="{active: isActiveClass(fr.all)}">
            <input type="checkbox" id="fieldRequestsAll"
                   class="form-check-input form-check-input_bg-blue"
                   @click="toggleFile(fr.all)">
            <label for="fieldRequestsAll">All Requests</label>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <base-button title="Export" :loading="ui.loading" @click-btn="exportFiles" :disabled="!allowExport" />
        <base-button title="Cancel" action="secondary" @click-btn="$emit('close')" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '../../../components/BaseButton.vue';
export default {
  components: { BaseButton },
  name: 'ExportModal',
  props: {
    selectedIds: { type: Array, required: false }
  },
  data() {
    return {
      ui: {
        loading: false
      },
      selectedFiles: [],
      fr: {
        selected: 'selected',
        pending: 'pending',
        processed: 'processed',
        all: 'all'
      }
    }
  },
  methods: {
    toggleFile(file) {
      this.selectedFiles.includes(file)
        ? this.selectedFiles.splice(this.selectedFiles.indexOf(file), 1)
        : this.selectedFiles.push(file)
    },
    exportFiles() {
      let queryParams = new URLSearchParams({});

      for (let id of this.selectedIds) {
        queryParams.append('selected[]', id)
      }
      queryParams.set('pending', this.selectedFiles.includes(this.fr.pending) ? 'true' : 'false')
      queryParams.set('processed', this.selectedFiles.includes(this.fr.processed) ? 'true' : 'false')
      queryParams.set('all', this.selectedFiles.includes(this.fr.all) ? 'true' : 'false')

      this.goto(this.backendUrl('/mlo/funding-requests/export?' + queryParams.toString()))
    },
    isActiveClass(file) {
      return this.selectedFiles.includes(file)
    }
  },
  computed: {
    allowExport() {
      return this.selectedFiles.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-block {
    height: 55%;
  }

  .modal-footer {
    bottom: unset;
  }
}

.check-block {
  display: flex;
  align-items: center;
  border: 1px solid rgba(231, 232, 232, 1);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  padding: 12px 12px;
  width: fit-content;

  &.active {
    border: 1px solid rgba(1, 122, 255, 1);
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
  }

  label {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
    margin: 0 0 0 12px;
    white-space: nowrap;
    cursor: pointer;
  }

  .form-check-input {
    height: 18px;
    max-width: 18px;
    margin: 0;
    cursor: pointer;

    &:checked {
      background-color: #0d6efd;
      border-color: #0d6efd;
    }
  }
}

</style>
