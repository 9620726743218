<template>
  <div>
    <div class="page-content p-normal">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="search-wrapper">
            <img src="@/assets/icons/icon-search.svg" alt="Icon search">
            <input type="text" placeholder="Search" v-model="search.query" @keyup="searchFundingRequests">
          </div>
        </div>
      </div>
      <div class="text-center mt-5" v-if="ui.loading === true">
        <div class="spinner-border mt-5" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="table" v-if="ui.loading === false">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">Borrower</th>
            <th scope="col">MLO</th>
            <th scope="col">Lender</th>
            <th scope="col">Broker Wire</th>
            <th scope="col">Status / Score</th>
            <th scope="col">Submitted On</th>
            <th scope="col">Due to Salesperson</th>
            <th scope="col">Actions</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(fr, index) in deletedRequests" :key="index">
            <td class="cursor-pointer table-borrower-name" @click="toggleModal('fundingDetails', fr.id)">
              {{ fr.borrowerName }}
            </td>
            <td>
              <div class="agents-avatars" v-if="isGranted('ROLE_BOOKKEEPER')">
                <a v-for="a in fr.agents" :key="fr.id + '_' + a.id" :href="backendUrl('/mlo/account/agent/' + a.id)" target="_blank">
                  <img :src="agentPhoto(a.photo)" :alt="a.name" v-tippy :content="a.name">
                </a>
              </div>
              <div class="agents-avatars" v-else>
                <img v-for="a in fr.agents" :key="fr.id + '_' + a.id" :src="agentPhoto(a.photo)" :alt="a.name" v-tippy :content="a.name">
              </div>
            </td>
            <td>{{ fr.lender }}</td>
            <td>
              <span v-tippy :content="capitalize(fr.type)">
                <span v-if="fr.type !== 'correspondent' || isGranted('ROLE_FUNDER')">
                  <b>{{ convertToCurrency(fr.wireAmount) }}</b>
                </span>
                <span v-else><b>N/A</b></span>
                ({{ formatDate(fr.wireDate) }})
              </span>
            </td>
            <td>
              <template v-if="isGranted(ROLE_BOOKKEEPER)">
                <div class="btn-group">
                  <div class="btn btn-br-default" :class="getStatusClass(fr.status)"
                       @click="toggleModal('scoreModal', fr.id)">
                    {{ fr.statusForHumans }}
                  </div>
                  <div class="btn btn-br-default" :class="getScoreClass(fr.score)"
                       @click="toggleModal('scoreModal', fr.id)">
                    <span>
                      {{ fr.score === null ? loadScore(fr.id) : fr.score }}
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="fr.score === null"/>
                    </span>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="btn btn-br-default" :class="getStatusClass(fr.status)">
                  {{ fr.statusForHumans }}
                </div>
              </template>
            </td>
            <td>{{ formatDate(fr.dateOfSubmission, 'MMM D, Y') }}</td>
            <td>
              <b>{{ convertToCurrency(fr.dueToSalesperson) }}</b>
            </td>
            <td class="actions-buttons">
              <button class="btn btn-primary" @click="toggleModal('fundingDetails', fr.id)">
                Details
              </button>
              <button class="btn btn-primary" @click="restore(fr)">
                Restore
              </button>
            </td>
          </tr>
          <tr v-if="deletedRequests.length === 0">
            <td colspan="9" class="text-center">
              <div class="m-5">
                <strong class="no-result">No borrower yet.</strong><br>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <pagination
        v-if="ui.loading === false"
        class="justify-content-start"
        :from="pagination.from"
        :to="pagination.to"
        :total="pagination.total"
        :page="pagination.page"
        :per-page="pagination.perPage"
        @update="getPendingRequests"/>
    </div>

    <transition name="component-fade" mode="out-in">
      <export-modal
        v-if="modals.exportModal === true"
        :selected-ids="getSelectedIds()"
        @close="modals.exportModal = false"/>
    </transition>
    <transition name="component-fade" mode="out-in">
      <funding-details-modal
        :funding-request-id="selectedFundingRequestId"
        v-if="modals.fundingDetails === true"
        @close="toggleModal"
        @editFunding="modals.editFunding = true"/>
    </transition>
    <transition name="component-fade" mode="out-in">
      <score-modal
        :funding-request-id="selectedFundingRequestId"
        v-if="modals.scoreModal === true"
        @close="toggleModal"/>
    </transition>
    <transition name="component-fade" mode="out-in">
      <edit-funding-modal
        :funding-request-id="selectedFundingRequestId"
        v-if="modals.editFunding === true"
        @close="toggleModal"/>
    </transition>
  </div>
</template>

<script>
import ExportModal from "./modals/ExportModal";
import FundingDetailsModal from "./modals/FundingDetailsModal";
import EditFundingModal from "./modals/EditFundingModal";
import ScoreModal from "./modals/ScoreModal";
import Pagination from "../../components/Pagination";

export default {
  name: "FundingRequestDeleted",
  components: {ExportModal, FundingDetailsModal, EditFundingModal, ScoreModal, Pagination},
  data() {
    return {
      showSelect: false,
      showModalNewFunding: false,
      showModalEditFunding: false,
      pendingRequests: [],
      processedRequests: [],
      deletedRequests: [],
      transactions: [],
      selectAllCheckbox: false,
      selectedFundingRequestId: null,
      selectedCount: 0,
      waitingForPaymentTotal: 0,
      pendingTotal: 0,
      status: null,
      ui: {
        loading: false,
        pay: {loading: []}
      },
      modals: {
        exportModal: false,
        fundingDetails: false,
        editFunding: false,
        customFeeModal: false,
        scoreModal: false,
      },
      payment: {
        customFee: false
      },
      search: {
        timer: null,
        query: null
      },
      pagination: {
        from: 0,
        to: 0,
        total: 0,
        page: 1,
        perPage: 10
      }
    }
  },
  methods: {
    getPendingRequests(pagination = null) {
      this.ui.loading = true;
      let params = pagination ? pagination : this.pagination
      params.query = this.search.query
      params.status = this.status ? this.status : null
      this.$http.get('/api/v1/funding-requests/deleted', {params: params})
        .then((res) => {
          this.ui.loading = false;
          this.deletedRequests = res.data.deletedRequests
          this.pagination = res.data.pagination
          this.waitingForPaymentTotal = res.data.inReviewTotal
          this.pendingTotal = res.data.pendingTotal
          this.deletedRequests.map((request) => {
            request.checked = false;
          })
        })
        .catch()
    },
    loadScore(id) {
      this.$http.get(`/api/v1/funding_requests/get-score/${id}`)
        .then((res) => {
          this.deletedRequests.map((request) => {
            if (request.id === id) {
              request.score = res.data.score;
            }
          })
        })
    },
    showSelectNav() {
      this.showSelect = !this.showSelect;
    },
    getRequestsByStatus(status) {
      this.status = status
      this.getPendingRequests()
    },
    selectAll(val) {
      if (val === false) {
        this.pendingRequests.map((request) => {
          request.checked = true;
          this.selectedCount++;
        })
      } else {
        this.pendingRequests.map((request) => {
          request.checked = false;
          this.selectedCount--;
        })
      }
    },
    restore(fr) {
        this.$http
          .post(`/api/v1/funding-requests/restore/${fr.id}`)
          .then(() => {
            this.deletedRequests = this.deletedRequests.filter(f => f.id !== fr.id);
            this.alertSuccess(`${fr.borrowerName} has been restored`);
          }).catch((e) => {
            let msg = this.getFirstErrorFromResponse(e.response)
            this.alertError(`Error: ${msg}`);
        })
    },
    toggleModal(modalName, fundingId = null, updateTableData = false) {
      this.modals[modalName] = !this.modals[modalName]
      this.selectedFundingRequestId = fundingId
      if (updateTableData) {
        this.getPendingRequests()
      }
    },
    pay(fr) {
      this.ui.pay.loading.push(fr.id);
      this.$http
        .post(`/api/v1/funding_requests/pay/${fr.id}`)
        .then(() => {
          this.pendingRequests = this.pendingRequests.filter((f) => f.id !== fr.id);
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', {
            msg: `${fr.borrowerName} has been successfully paid`,
            status: 'success',
            delay: 2000
          });
        })
        .catch((e) => {
          let err = this.getFirstErrorFromResponse(e.response)
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', {
            msg: `Error! ${err}`,
            status: 'error',
            delay: 3000
          });
        }).finally(() => {
        this.ui.pay.loading = this.ui.pay.loading.filter(id => id !== fr.id)
      })
    },
    openPaymentCustomModal(id) {
      this.selectedFundingRequestId = id;
      this.modals.payment = true;
      this.payment.customFee = true;
    },
    toggleCheckboxItem(id) {
      this.selectedCount = 0
      this.pendingRequests.map(item => {
        if (item.id === id) {
          item.checked = !item.checked
        }

        if (item.checked)
          this.selectedCount++
      })

      let checkbox = document.getElementById('checkbox-select')
      if (this.selectedCount > 0 && this.selectedCount < this.pendingRequests.length) {
        checkbox.indeterminate = true
      } else if (this.selectedCount === this.pendingRequests.length) {
        checkbox.indeterminate = false
        checkbox.checked = true
      } else {
        checkbox.indeterminate = false
        checkbox.checked = false
      }
    },
    searchFundingRequests() {
      if (this.search.timer) {
        clearTimeout(this.search.timer)
      }

      this.search.timer = setTimeout(() => {
        this.getPendingRequests()
      }, 800)
    },
    getSelectedIds() {
      let ids = [];

      this.pendingRequests.map(item => {
        if (item.checked) {
          ids.push(item.id)
        }
      })

      return ids
    },
    getScoreClass(score) {
      if (score >= 70) {
        return 'btn-success'
      } else if (score >= 40 && score < 70) {
        return 'btn-primary'
      } else if (score < 40) {
       return 'btn-danger'
      }
    },
    getStatusClass(status) {
      if (status === 'pending') {
        return 'btn-secondary'
      } else if (status === 'error') {
        return 'btn-danger'
      } else if (status === 'waiting_for_payment' || status === 'paid') {
        return 'btn-success'
      }
    }
  },
  computed: {
    currentPage() {
      return this.$route.name
    },
    editAllow() {
      return this.selectedCount !== 1
    },
    deleteAllow() {
      return this.selectedCount === 0
    }
  },
  beforeMount() {
    this.getPendingRequests()
  }
}
</script>

<style lang="scss" scoped>
.table {
  tbody {
    tr {
      vertical-align: middle;

      td {
        color: rgba(0, 0, 0, .8);
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;

        &:not(:first-child) {
          padding-left: 0;
        }
      }

      .btn {
        max-height: 32px;
        height: 32px;
        font-size: 12px;
        line-height: 1.5;
      }

      .agents-avatars {
        img {
          height: 34px;
          width: 34px;
          border: 2px solid #F6F6F6;
          border-radius: 50%;

          &:nth-child(n + 2) {
            margin-left: -5px;
          }
        }
      }
    }
  }

  .actions-buttons {
    .btn {
      margin-right: 0.25rem;
    }
  }
}

.modal {
  .tab-list {
    padding: 0;

    li {
      &:first-child {
        margin: 0;
      }
    }
  }
}

.form-check-input {
  border-width: 2px;
}
</style>
